<template>
	<div class="player">
		<audio ref="audio" id="audio" preload="auto"></audio>
	</div>
</template>

<script>
export default {
	data: () => ({
		audio: undefined,
		audioId: null,
		nowPlaying: null,
		loaded: false,
		playing: false
	}),
	watch: {
		playing() {
			if (!this.playing) {
				this.$parent.$refs.map.deselectFeature()
				this.$parent.$refs.infoBox.resetAudioInfo()
			}
		}
	},
	methods: {
		load() {
			if (this.audio.readyState >= 2) {
				this.loaded = true
			}
			else {
				throw new Error('Failed to load sound file.')
			}
		},
		setMedia(audio) {
			if (audio.id == this.audioId)
			{
				if (this.playing) {
					this.pause()
				}
				else {
					this.play()
				}
			}
			else {
				this.audioId = audio.id
				this.nowPlaying = audio
				this.audio.setAttribute("src", audio.file +"?"+ Date.now())
				this.play()
			}
		},
		play() {
			this.playing = true
			this.audio.play()
			this.$parent.$refs.infoBox.setAudioInfo(this.nowPlaying.description)
		},
		pause() {
			this.playing = false
			this.audio.pause()
		},
		stop() {
			this.playing = false
			this.audio.setAttribute("src", '')
			this.audio.currentTime = 0
			this.audioUrl = null
		}
	},
	mounted() {
		this.audio = this.$refs['audio']
		this.audio.addEventListener('loadeddata', this.load)
		this.audio.addEventListener('pause', () => { this.playing = false; })
		this.audio.addEventListener('play', () => { this.playing = true; })
	}
}
</script>
