<template>
	<div id="app">
		<Map ref="map"/>
		<InfoBox ref="infoBox"/>
		<PhraseList ref="phraseList"/>
		<Overlay ref="overlay"/>
		<Player ref="player"/>
	</div>
</template>

<script>
import Map from './components/Map.vue'
import InfoBox from './components/InfoBox.vue'
import PhraseList from './components/PhraseList.vue'
import Overlay from './components/Overlay.vue'
import Player from './components/Player.vue'

export default {
	name: 'App',
	components: {
		Map,
		InfoBox,
		PhraseList,
		Overlay,
		Player
	}
}
</script>
