<template>
	<div class="info-box-container" v-bind:class="{ active: active }">
		<div class="info-box">
			<a href="#" class="close" @click.prevent="close" title="Schliessen">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
			</a>
			<div class="header">
				<h2 v-html="phraseTitle"></h2>
				<div class="audio-info" v-bind:class="{ active: audioInfo }">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path></svg>	
					<div v-html="audioInfo"></div>
				</div>
			
			</div>
			<div class="body">
				<div class="phrase-info lead" v-html="phraseInfo"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active: false,
			phraseTitle: null,
			phraseInfo: null,
			audioInfo: false,
		}
	},
	methods: {
		setPhraseInfo: function (phrase) {
			this.phraseTitle = phrase.title
			this.phraseInfo = phrase.description
			this.open()
		},
		setAudioInfo: function (info) {
			this.audioInfo = info
			this.open()
		},
		resetAudioInfo: function () {
			this.audioInfo = false
		},
		open: function () {
			this.active = true
			var phrseList = this.$parent.$refs.phraseList;
			phrseList.collapse()
			phrseList.loadingId = null
		},
		close: function () {
			this.active = false
		},
	},
}
</script>
