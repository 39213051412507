<template>
	<div class="phrase-list-container" v-bind:class="{ collapsed: collapsed }">
		<div class="phrase-list">
			<div class="header text-center" @click="toggleCollapse">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="chevron"><polyline points="6 9 12 15 18 9"></polyline></svg>
				<div class="header-content">
					<h1>Bitte wählen</h1>
					<p class="lead">Begriff wählen und die Dialektunterschiede auf der Karte anhören.</p>
				</div>
			</div>
			<div class="list-group">
				<a href="#" class="list-group-item list-group-item-action" :class="{ active: currentPhraseId == phrase.id }" @click.prevent="fetchPhrase(phrase.id)" :key="phrase" v-for="phrase in phrases">
					{{ phrase.title }}
					<span class="loading spinner-border spinner-border-sm text-danger" v-bind:class="ifLoading(phrase.id)" role="status">
						<span class="sr-only">Laden...</span>
					</span>
				</a>
			</div>
			<div class="footer fadein">
				<div class="btn-group d-flex" role="group">
					<a class="btn btn-primary" href="#" @click.prevent="openOverlay('instructions')">Einführung</a>
					<a class="btn btn-primary" href="#" @click.prevent="openOverlay('glossary')">Glossar</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			phrases: null,
			currentPhraseId: null,
			collapsed: false,
			loadingId: null,
		}
	},
	methods: {
		fetchList: function () {
			axios.get('phrase/list').then((result) => {
				this.phrases = result.data
			})
		},
		fetchPhrase: function (id) {
			this.loadingId = id
			var infoBox = this.$parent.$refs.infoBox
			if (id != this.currentPhraseId) {
				var obj = this
				axios.get('phrase/'+ id).then((result) => {
					obj.$parent.$refs.player.stop()
					var phrase = result.data
					this.currentPhraseId = phrase.id
					
					this.$parent.$refs.map.setPolygons(phrase.polygons)
					this.$parent.$refs.map.setPois(phrase.pois)
					
					infoBox.setPhraseInfo(phrase)
				})
			}
			else {
				infoBox.open()
			}
		},
		toggleCollapse: function() {
			if (this.collapsed) {
				this.collapsed = false
				this.$parent.$refs.infoBox.close()
			}
			else {
				this.collapsed = true
			}
		},
		collapse: function () {
			this.collapsed = true
		},
		openOverlay: function (slug) {
			this.$parent.$refs.overlay.open(slug)
		},
		ifLoading: function (id) {
			if (this.loadingId == id) {
				return 'active'
			}
			
		}
	},
	mounted() {
		this.fetchList()
	}
}
</script>
