<template>
	<div class="overlay" v-bind:class="{ active: active }">
		<div class="overlay-box">
			<a href="#" class="close" @click.prevent="close" title="Schliessen">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
			</a>
			<div class="header">
				<h2 v-html="overlayTitle"></h2>
			</div>
			<div class="body">
				<div class="overlay-content" v-html="overlayContent"></div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			active: false,
			overlayTitle: null,
			overlayContent: null,
		}
	},
	methods: {
		fetchPage: function (slug) {
		
			
			axios.get('page/'+ slug).then((result) => {
				var data = result.data
				this.overlayTitle = data.title
				this.overlayContent = data.content
				this.active = true
			})
		},
		open: function (slug) {
			this.fetchPage(slug)
		},
		close: function () {
			this.active = false
		},
	},
}
</script>
