<template>
	<div class="map-container">
		<div ref="map" class="map"></div>
		<div class="credits small">&copy;swisstopo</div>
	</div>
</template>

<script>
import axios from 'axios'
import 'ol/ol.css';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';
import View from 'ol/View';
import {addCoordinateTransforms, addProjection, transform} from 'ol/proj';

import {Icon, Fill, Stroke, Style} from 'ol/style';
import {Vector as VectorSource} from 'ol/source';

import GeoJSON from 'ol/format/GeoJSON'

//const extent = [420000, 30000, 900000, 350000];
const extent = [485869.5728, 76443.1884, 837076.5648, 299941.7864];
const projection = new Projection({
	code: 'EPSG:21781',
	extent: extent,
	units: 'm',
});
addProjection(projection);

var perimeterVectorSource = new VectorSource();
var polygonVectorSource = new VectorSource();
var poiVectorSource = new VectorSource();

(new Image()).src = 'img/pause-circle.svg';

export default {
	props: {},
	data: () => ({
		map: false,
		selected: null,
		layers: {
			'topo': null,
			'communes': null,
			'perimeter': null,
			'polygons': null,
			'pois': null
		}
	}),
	methods: {
		selectFeature(e) {
			var obj = this;
			this.map.forEachFeatureAtPixel(e.pixel, function(feature) {
				obj.deselectFeature()
				obj.selected = feature
				obj.$parent.$refs.player.setMedia(feature.getProperties())
				feature.setStyle(
					new Style({
						image: new Icon({
							src: 'img/pause-circle.svg',
						})
					})
				)
				return true
			},
			{
				layerFilter: function(layer) {
					return (layer.get('id') === 'poi-layer')
				}
			})
		},
		deselectFeature() {
			if (this.selected !== null) {
				this.selected.setStyle(undefined)
				this.selected = null;
			}
		},
		onPointerMove(e) {
			// change mouse cursor when over marker
			var pixel = this.map.getEventPixel(e.originalEvent);
			var hit = this.map.hasFeatureAtPixel(pixel, {
				layerFilter: function (layer) {
					return (layer.get('id') === 'poi-layer')
				}
			});
			this.map.getTarget().style.cursor = hit ? 'pointer' : '';
		},
		setPerimeter(polygon) {
			perimeterVectorSource.addFeatures(
				new GeoJSON().readFeatures(polygon)
			)
		},
		setPolygons(polygons) {
			polygonVectorSource.clear()
			polygonVectorSource.addFeatures(
				new GeoJSON().readFeatures(polygons)
			)
		},
		setPois(pois) {
			poiVectorSource.clear()
			poiVectorSource.addFeatures(
				new GeoJSON().readFeatures(pois)
			)
		},
		fetchPerimeter: function () {
			var obj = this
			axios.get('meta/perimeter').then((result) => {
				obj.setPerimeter(result.data)
			})
		},
	},
	mounted() {
		this.map = new Map({
			target: this.$refs['map'],
			//controls: [],
			layers: [
				this.layers.topo = new TileLayer({
					id: "topo-layer",
					//extent: extent,
					opacity: 0.7,
					source: new TileWMS({
						url: 'https://wms.geo.admin.ch/',
						params: {
							'LAYERS': 'ch.swisstopo.pixelkarte-grau',
							'FORMAT': 'image/jpeg',
						},
						serverType: 'mapserver',
					}),
				}),
				this.layers.communes = new TileLayer({
					id: "communes-layer",
					//extent: extent,
					opacity: 0.2,
					source: new TileWMS({
						url: 'https://wms.geo.admin.ch/',
						params: {
							'LAYERS': 'ch.swisstopo.swissboundaries3d-gemeinde-flaeche.fill',
							'FORMAT': 'image/jpeg',
						},
						serverType: 'mapserver',
					})
				}),
				this.layers.perimeter = new VectorLayer({
					id: "perimeter-layer",
					opacity: 0.8,
					source: perimeterVectorSource,
					style: function() {
						return new Style({
							stroke: new Stroke({
								color: '#152038',
								width: 3
							}),
						})
					}
				}),
				this.layers.polygons = new VectorLayer({
					id: "polygon-layer",
					opacity: 0.6,
					source: polygonVectorSource,
					style: function(feature) {
						return new Style({
							fill: new Fill({
								color: feature.get('color'),
							}),
						})
					}
				}),
				this.layers.pois = new VectorLayer({
					id: "poi-layer",
					opacity: 1,
					source: poiVectorSource,
					style: new Style({
						image: new Icon({
							src: 'img/play-circle.svg',
						}),
					}),
				}),
			],
			view: new View({
				projection: projection,
				center: transform([8.3, 46.4], 'EPSG:4326', 'EPSG:21781'),
				extent: extent,
				enableRotation: false,
				zoom: 3,
				minZoom: 3,
				maxZoom: 6
			}),
		});
		
		this.map.on('pointermove', this.onPointerMove)
		this.map.on('click', this.selectFeature)
		this.fetchPerimeter()
	}
}


// We also declare EPSG:21781/EPSG:4326 transform functions. These functions
// are necessary for the ScaleLine control and when calling ol/proj~transform
// for setting the view's initial center (see below).

addCoordinateTransforms(
	'EPSG:4326',
	projection,
	function (coordinate) {
		return [
			WGStoCHy(coordinate[1], coordinate[0]),
			WGStoCHx(coordinate[1], coordinate[0]),
		];
	},
	function (coordinate) {
		return [
			CHtoWGSlng(coordinate[0], coordinate[1]),
			CHtoWGSlat(coordinate[0], coordinate[1]),
		];
	}
);

/*
 * Swiss projection transform functions downloaded from
 * https://www.swisstopo.admin.ch/en/knowledge-facts/surveying-geodesy/reference-systems/map-projections.html
 */

// Convert WGS lat/long (° dec) to CH y
function WGStoCHy(lat, lng) {
  // Converts degrees dec to sex
  lat = DECtoSEX(lat);
  lng = DECtoSEX(lng);

  // Converts degrees to seconds (sex)
  lat = DEGtoSEC(lat);
  lng = DEGtoSEC(lng);

  // Axillary values (% Bern)
  const lat_aux = (lat - 169028.66) / 10000;
  const lng_aux = (lng - 26782.5) / 10000;

  // Process Y
  const y =
    600072.37 +
    211455.93 * lng_aux -
    10938.51 * lng_aux * lat_aux -
    0.36 * lng_aux * Math.pow(lat_aux, 2) -
    44.54 * Math.pow(lng_aux, 3);

  return y;
}

// Convert WGS lat/long (° dec) to CH x
function WGStoCHx(lat, lng) {
  // Converts degrees dec to sex
  lat = DECtoSEX(lat);
  lng = DECtoSEX(lng);

  // Converts degrees to seconds (sex)
  lat = DEGtoSEC(lat);
  lng = DEGtoSEC(lng);

  // Axillary values (% Bern)
  const lat_aux = (lat - 169028.66) / 10000;
  const lng_aux = (lng - 26782.5) / 10000;

  // Process X
  const x =
    200147.07 +
    308807.95 * lat_aux +
    3745.25 * Math.pow(lng_aux, 2) +
    76.63 * Math.pow(lat_aux, 2) -
    194.56 * Math.pow(lng_aux, 2) * lat_aux +
    119.79 * Math.pow(lat_aux, 3);

  return x;
}

// Convert CH y/x to WGS lat
function CHtoWGSlat(y, x) {
  // Converts military to civil and to unit = 1000km
  // Axillary values (% Bern)
  const y_aux = (y - 600000) / 1000000;
  const x_aux = (x - 200000) / 1000000;

  // Process lat
  let lat =
    16.9023892 +
    3.238272 * x_aux -
    0.270978 * Math.pow(y_aux, 2) -
    0.002528 * Math.pow(x_aux, 2) -
    0.0447 * Math.pow(y_aux, 2) * x_aux -
    0.014 * Math.pow(x_aux, 3);

  // Unit 10000" to 1 " and converts seconds to degrees (dec)
  lat = (lat * 100) / 36;

  return lat;
}

// Convert CH y/x to WGS long
function CHtoWGSlng(y, x) {
  // Converts military to civil and to unit = 1000km
  // Axillary values (% Bern)
  const y_aux = (y - 600000) / 1000000;
  const x_aux = (x - 200000) / 1000000;

  // Process long
  let lng =
    2.6779094 +
    4.728982 * y_aux +
    0.791484 * y_aux * x_aux +
    0.1306 * y_aux * Math.pow(x_aux, 2) -
    0.0436 * Math.pow(y_aux, 3);

  // Unit 10000" to 1 " and converts seconds to degrees (dec)
  lng = (lng * 100) / 36;

  return lng;
}

// Convert DEC angle to SEX DMS
function DECtoSEX(angle) {
  // Extract DMS
  const deg = parseInt(angle, 10);
  const min = parseInt((angle - deg) * 60, 10);
  const sec = ((angle - deg) * 60 - min) * 60;

  // Result in degrees sex (dd.mmss)
  return deg + min / 100 + sec / 10000;
}

// Convert Degrees angle to seconds
function DEGtoSEC(angle) {
  // Extract DMS
  const deg = parseInt(angle, 10);
  let min = parseInt((angle - deg) * 100, 10);
  let sec = ((angle - deg) * 100 - min) * 100;

  // Avoid rounding problems with seconds=0
  const parts = String(angle).split('.');
  if (parts.length == 2 && parts[1].length == 2) {
    min = Number(parts[1]);
    sec = 0;
  }

  // Result in degrees sex (dd.mmss)
  return sec + min * 60 + deg * 3600;
}
</script>
